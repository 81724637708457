/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.bg-auth {
  background-image: url('/assets/images/backgrounds/bg.jpg') !important;
}

.bg-content-desktop {
  background-color: red;
}

.mdc-dialog .mdc-dialog__content,
.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 0 !important;
}

.cdk-overlay-dark-backdrop,
.red-bg {
  background-color: rgba(44, 6, 4, 0.81);
}

.mat-mdc-dialog-content {
  max-height: 86vh !important;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  border-radius: 25px !important;
}

.mat-mdc-snack-bar-container.snack-bar-error,
.mat-mdc-snack-bar-container.error-snackbar {
  .mat-mdc-simple-snack-bar {
    background-color: #f43f5e;
    color: white;
    border-radius: 25px;
  }

  .mdc-snackbar__surface {
    padding-right: 0;
  }

  .mdc-snackbar__label {
    color: #fff;
    background-color: #f43f5e;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  .mdc-snackbar__actions {
    background-color: #f43f5e;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}

.mat-mdc-snack-bar-container.snack-bar-success,
.mat-mdc-snack-bar-container.success-snackbar {
  .mat-mdc-simple-snack-bar {
    background-color: #4caf50;
    color: white;
    border-radius: 25px;
  }

  .mdc-snackbar__surface {
    padding-right: 0;
  }

  .mdc-snackbar__label {
    color: #fff;
    background-color: #4caf50;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  .mdc-snackbar__actions {
    background-color: #4caf50;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}

.mat-mdc-snack-bar-container.snack-bar-info,
.mat-mdc-snack-bar-container.info-snackbar {
  .mat-mdc-simple-snack-bar {
    background-color: dodgerblue;
    color: white;
    border-radius: 25px;
  }

  .mdc-snackbar__surface {
    padding-right: 0;
  }

  .mdc-snackbar__label {
    color: #fff;
    background-color: dodgerblue;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }

  .mdc-snackbar__actions {
    background-color: #4caf50;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
  }
}

#file-upload-button {
  display: none !important;
}
